import { createTheme } from '@material-ui/core/styles'
import palette from './palette'
import breakpoints from './breakpoints'
import typography from './typography'
import shadows from './shadows'
import transitions from './transitions'

const mainTheme = {
    breakpoints,
    palette,
    typography,
    shadows,
    direction: 'ltr',
    transitions,
    mixins: {
        toolbar: {
            'minHeight': 56,
            '@media (min-width:0px) and (orientation: landscape)': { minHeight: 48 },
            '@media (min-width:600px)': { minHeight: 64 },
        },
    },
    shape: { borderRadius: 4 },
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
}

export default createTheme(mainTheme)
