export default {
    grey: {
        main: '#9e9e9e',
        light: '#e0e0e0',
        dark: '#616161',
        contrastText: '#fff',
    },
    pastaRed: {
        main: '#af3443',
        light: '#cc5261',
        dark: '#912b37',
        contrastText: '#fdf9f9',
    },
    pastaGreen: {
        main: '#37932b',
        light: '#61cd53',
        dark: '#2c7522',
        contrastText: '#fdf9f9',
    },
}
